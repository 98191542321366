// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Row} from 'antd';

import ContentHeader from "../../components/Typo/ContentHeader";
import DeviceConnectionCreateForm from "./form";
import Panel from "../Panel";
import _ from "lodash";

type Props = {
    history: {
        push: Function
    },
    t: Function,
}

class ProfileDeviceConnectionCreate extends Component<Props> {
    render() {
        const {t, history} = this.props;
        return <div style={{width: '100%'}}>
            <div style={{width: '100%'}}>
                <ContentHeader title={t('Add new Device Connection')}/>
            </div>
            <Row style={{width: '100%'}}>
                <Col span={24}>
                    <div style={{margin: '10px'}}>
                        <Panel title={t('New Device Connection')}>
                            <DeviceConnectionCreateForm
                                onCreated={(res) => {
                                    const id = _.get(res, 'data.createDeviceConnection.id')
                                    if (id) {
                                        history.push(`/profile/deviceConnections/${id}`)
                                    }
                                }}
                            />
                        </Panel>
                    </div>
                </Col>
            </Row>
        </div>;
    }
}

export default withTranslation()(withRouter(ProfileDeviceConnectionCreate));
