// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import moment from "moment";
import DeviceConnectionList from "../../graphql/queries/deviceConnectionList.graphql";
import QueryTable from "../QueryTable";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

type Props = {
    t: Function,
    onClick: Function,
    accessTokenListQuery: query,
};

type State = {
    loading: boolean,
}

class DeviceConnectionsListTable extends Component<Props, State> {

    state = {
        loading: false,
    };

    render() {
        const {deviceConnectionListQuery, t, onClick} = this.props;

        return <QueryTable
            loading={this.state.loading}
            className={"link-table"}
            size={'middle'}
            query={deviceConnectionListQuery}
            itemsKeyPath={'deviceConnectionList.deviceConnections'}
            totalKeyPath={'deviceConnectionList.total'}
            onRow={(record) => {
                return {
                    onClick: () => onClick ? onClick(record) : null
                };
            }}
            rowKey={"id"}
            columns={[
                {
                    dataIndex: 'name',
                    key: 'name',
                    title: t('Name')
                },
                {
                    dataIndex: 'insert',
                    key: 'insert',
                    title: t('Login'),
                    render: (text) => moment(text).format('L LT')
                },
                {
                    dataIndex: 'uuid',
                    key: 'uuid',
                    title: t('UUID'),
                },
                {
                    dataIndex: 'type',
                    key: 'type',
                    title: t('Type'),
                    render: (text) => capitalizeFirstLetter(text),
                }
            ]}/>;
    }
}


export default compose(
    withTranslation(),
    graphql(DeviceConnectionList, {
        name: 'deviceConnectionListQuery',
        options: () => ({
            fetchPolicy: "cache-and-network",
        }),
    }),
)(DeviceConnectionsListTable);

