import axios from "axios";
import _ from "lodash";

const keycloakConfig = _.get(window, '__BE__.keycloak')
const keycloakUrl = keycloakConfig?.url || "https://sso.pfeiffer-vacuum.com/auth"
const keycloakRealm = keycloakConfig?.realm || "dev"
const keycloakClientId = "vsm-workflowboard"

export async function fetchDeviceGrantCode() {
    const response = await axios.post(
        `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/auth/device`,
        new URLSearchParams({
            client_id: keycloakClientId,
            scope: "openid profile email", // Angeforderte Scopes
        }),
        {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
    );

    return response.data; // Enthält device_code, user_code, verification_uri, etc.
}

export async function pollForToken(deviceCode, pollInterval) {
    const tokenEndpoint = `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`;
    const interval = pollInterval;

    while (true) {
        try {
            const response = await axios.post(
                tokenEndpoint,
                new URLSearchParams({
                    grant_type: "urn:ietf:params:oauth:grant-type:device_code",
                    client_id: keycloakClientId,
                    device_code: deviceCode,
                }),
                { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
            );

            if (response.data.access_token) {
                console.log("Access Token:", response.data.access_token);
                return response.data; // Token erhalten
            }
        } catch (error) {
            if (error.response?.status === 400 && error.response.data.error === "authorization_pending") {
                // Warten auf Autorisierung
                console.log("Warten auf Benutzer...");
                await new Promise((resolve) => setTimeout(resolve, interval));
            } else {
                console.error("Fehler beim Polling:", error);
                throw error;
            }
        }
    }
}
