// @flow
import React, {Component, ReactNode} from 'react';
import {AttributesForm, ModalAttributeForm} from "@dreebit-registry/form-attributes-vsm";
import {compose, withProps} from "recompose";
import {AttributeParser} from "@dreebit/form-attributes-tda-helper";
import {connect} from "react-redux"
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {Alert, Avatar, Modal, Tooltip} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {toastError, toastSuccess} from "../../utils/toast";
import Strings from "../../i18n/strings";
import withProfile from "../../hoc/withProfile";
import {checkResource} from "../../hoc/withResources";
import {withApollo} from "@apollo/client/react/hoc";
import Image from "../Utilities/Image";
import {Prompt, withRouter} from "react-router-dom";
import type {Attribute} from "../../types";
import {AttributeInputType} from "@dreebit/form-attributes-interfaces";
import {AppstoreOutlined, UserOutlined} from "@ant-design/icons";
import keycloak from "../../keycloak";


export type TDAAttributeFormOnChange = (changes: any, { allValues: any }) => void;

type Props = {
    className?: any,
    editable?: boolean,
    readOnly?: boolean,
    clearOnSubmit?: boolean,
    submitOnChange?: boolean,
    attributes: any[],
    formType?: 'default' | 'modal',
    disableSubmit?: boolean,
    groups?: any[],
    mutation?: Function,
    translateMutationOptions?: ({ values: any, changes: any, attributes: any }) => void,
    t: Function,
    layout?: any,
    formStyles?: {
        formItemLayout?: {
            labelCol: {
                xs: { span: number },
                sm: { span: number },
            },
            wrapperCol: {
                xs: { span: number },
                sm: { span: number },
            },
        };
        tailFormItemLayout?: any;
        editable?: boolean;
        withoutFormItem?: boolean;
    },
    onChange?: TDAAttributeFormOnChange,
    onError?: (error: Error) => void,
    submit?: boolean,
    clearApolloCache?: boolean,
    onSubmit?: Function,
    collapse?: string[],
    submitTitle?: string,
    values?: any,
    defaultValues?: any,
    successMessage?: string,
    errorMessage?: string,
    disableToasts?: boolean,
    errorPresentationMode?: 'toast' | 'box',
    profile?: any,
    cacheChanges?: boolean,
    history?: any,
    location?: any,
    blockNavigationOnUnsavedChanges?: boolean,
    hideLabels?: boolean,
    hideRequiredStar?: boolean,
    beforeSubmit?: ReactNode,
};

type State = {
    changes: any,
    visible: boolean,
    index: string,
    name: string,
    startDate: any,
    endDate: any,
    errorText: string,
    unsavedChanges: boolean,
    alertVisible: boolean,
    lastLocation: boolean,
    confirmedNavigation: boolean
}

class TDAAttributesForm extends Component<Props, State> {

    static defaultProps = {
        layout: 'horizontal',
        visible: false,
        index: null,
        cacheChanges: true,
        name: null,
        errorPresentationMode: 'toast',
        blockNavigationOnUnsavedChanges: false
    };

    state = {
        errorText: null,
        changes: {},
        unsavedChanges: false,
        alertVisible: false,
        lastLocation: null,
        confirmedNavigation: false,
    };

    showModal = (location) => this.setState({
        alertVisible: true,
        lastLocation: location,
    });

    closeModal = (callback) => this.setState({
        alertVisible: false
    }, callback);

    handleBlockedNavigation = (nextLocation) => {
        const {confirmedNavigation} = this.state;

        if (!confirmedNavigation && this.state.unsavedChanges) {
            this.showModal(nextLocation);
            return false
        }

        return true
    };

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const {history} = this.props;
        const {lastLocation} = this.state;

        if (lastLocation) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                history.push(lastLocation.pathname);
            })
        }
    });

    componentWillUnmount() {
        this.setState({
            alertVisible: false,
            unsavedChanges: false,
            confirmedNavigation: false,
            lastLocation: null
        });
    }

    handleSubmit = (values) => {
        const {
            mutation,
            attributes,
            clearApolloCache,
            client,
            translateMutationOptions,
            t,
            disableToasts,
            successMessage,
            errorMessage,
            onSubmit,
            clearOnSubmit,
            onChange
        } = this.props;

        if (!mutation || !_.isFunction(mutation)) {
            return console.error('No valid mutation in props', this.props)
        }

        let mutationValues = _.clone(values);

        if (!mutationValues.values){
            mutationValues = {values: mutationValues}
        }

        // Clean values
        mutationValues.values = Object.keys(mutationValues.values).reduce((acc, key) => {
            const value = mutationValues.values[key];
            const attribute = _.find(attributes, {index: key});

            if (value !== undefined && attribute && !attribute.disabled){
                acc[key] = value;
            }
            return acc;
        }, {});

        mutationValues.changes = this.state.changes;
        mutationValues.attributes = this.props.attributes;

        const args = translateMutationOptions ? translateMutationOptions(mutationValues) : {variables: mutationValues.values};

        const promise = mutation(args);

        if (!promise || !promise.then){
            return;
        }

        return promise
            .then( ((res) => {
                if (clearApolloCache && client){
                    return new Promise((resolve) => {
                        client.clearStore().then(() => resolve(res)).catch(()=> {
                            resolve(res);
                        })
                    })
                }
                return res
            }))
            .then((res) => {
                if (!disableToasts){
                    toastSuccess(successMessage ? successMessage : t('Successfully saved'));
                }
                return res;
            })
            .then((res) =>{
                if (onSubmit){
                    return onSubmit(res, values)
                }

                return res;
            })
            .then(() => {
                if (clearOnSubmit) {
                    this.setState({
                        changes: {}
                    });
                    if (onChange) {
                        onChange({}, {})
                    }
                }
                this.setState({
                    unsavedChanges: false
                });
                if (this.state.alertVisible) this.handleConfirmNavigationClick();
            })
            .catch((err) => {
                this.setState({
                    alertVisible: false,
                    errorText: _.first(_.get(err, 'networkError.result.errors')) || _.get(err, 'message', err || Strings.errorOccurred)
                });

                if (this.props.onError){
                    this.props.onError(err);
                }else if (this.props.errorPresentationMode === 'toast'){
                    toastError(_.first(_.get(err, 'networkError.result.errors')) || errorMessage || err || t('An error occurred'), {
                        error: err
                    });
                }
            })

    };


    handleOnChange = (changedValues, allValues, allFields) => {
        const {onChange, submitOnChange, attributes} = this.props;

        Object.keys(changedValues).forEach((valueKey: any) => {
            if (_.isArray(changedValues[valueKey]) && changedValues[valueKey].length === 0) {
                const attribute: Attribute<any> | undefined = _.find(attributes, {index: valueKey});

                if (attribute && attribute.inputType.toUpperCase() === AttributeInputType.DATERANGEPICKER) {
                    changedValues[valueKey] = null;
                    allValues[valueKey] = null;
                }
            }
        });

        this.setState({
            unsavedChanges: true,
            changes: {
                ...this.state.changes,
                ...changedValues
            }
        });

        const errors = _.chain(allFields).map((field) => field && field.errors ? field.errors : null).flatten().value();
        if (errors && errors.name){
            console.log(errors);
            return;
        }

        if (submitOnChange){
            this.handleSubmit(changedValues);
        }

        const cleanAllValues = Object.keys(allValues).reduce((acc, key) => {
            const value = allValues[key];
            const attribute = _.find(this.props.attributes, {index: key});

            if (value !== undefined && attribute && !attribute.disabled){
                acc[key] = value;
            }
            return acc;
        }, {});

        return onChange ? onChange(changedValues, {
            allValues: cleanAllValues
        }) : null
    };

    handleCloseModal = () => {
        this.setState({
            visible: false
        })
    };

    render() {
        let {
            attributes,
            t,
            when,
            confirm,
            clearOnSubmit,
            blockNavigationOnUnsavedChanges,
            collapse,
            hideRequiredStar,
            groups,
            hideLabels,
            values,
            defaultValues,
            useTabs,
            formStyles,
            beforeSubmit,
            afterSubmit,
            className,
            editable,
            submit,
            layout,
            disableSubmit,
            cacheChanges,
        } = this.props;
        let {changes, alertVisible, unsavedChanges} = this.state;

        const formValues = cacheChanges ? {
            ...defaultValues,
            ...values,
            ...this.state.changes
        } : {
            ...defaultValues,
            ...values,
        };

        attributes = attributes.map((attribute) => {
            const {t} = this.props;

            if (this.props.readOnly){
                attribute = {
                    ...attribute,
                    disabled: true
                }
            }

            if ((attribute.inputType === 'SELECT' || attribute.inputType === 'AUTOCOMPLETE') && (attribute.entityType === 'user' || attribute.entityType === 'userEmail')) {
                attribute = {
                    ...attribute,
                    inputProps: {
                        renderOption: (title, value, raw) => {
                            return <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', alignItems: 'center', margin: '0px 10px 0px 0px'}}>
                                    <Avatar size={35} src={_.get(raw, 'avatar.thumbnailLink')} icon={<UserOutlined/>}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{fontSize: 14, fontWeight: 'bold'}}>{title}</span>
                                    <span style={{fontSize: 12}}>{_.get(raw, 'email')}</span>
                                </div>
                            </div>
                        }
                    }
                }
            }
            if(attribute.inputType === 'SELECT' && attribute.entityType === 'client'){
                attribute = {
                    ...attribute,
                    inputProps: {
                        renderOption: (title, value, raw) => {
                            let id = _.get(raw, 'customerId');
                            return id
                                ? <span>
                                    {title} | {id}
                                </span>
                                :<span>
                                    {title}
                                </span>
                        }
                    }
                }
            }
            if(attribute.inputType === 'SELECT' && attribute.entityType === 'product'){
                attribute = {
                    ...attribute,
                    inputProps: {
                        renderOption: (title, value, raw) => {
                            return <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', alignItems: 'center', margin: '0px 10px 0px 0px'}}>
                                    <Avatar size={50} src={_.get(raw, 'image.thumbnailLink')} icon={<AppstoreOutlined/>}
                                            shape={'square'}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{fontSize: 14, fontWeight: 'bold'}}>{title}</span>
                                    <span style={{
                                        fontSize: 12,
                                        fontStyle: 'italic'
                                    }}>{`${t('catalogNo')}: ${_.get(raw, 'catalogNo')}`}</span>
                                    <span style={{
                                        fontSize: 12,
                                        fontStyle: 'italic'
                                    }}>{`${t('Manufacturer')}: ${_.get(raw, 'manufacturer.name')}`}</span>
                                </div>
                            </div>
                        }
                    }
                }
            }
            if(attribute.inputType === 'SELECT' && attribute.entityType === 'defaultServiceTask'){
                attribute = {
                    ...attribute,
                    inputProps: {
                        renderOption: (title, value, raw) => {
                            return <Tooltip title={_.get(raw, 'description')} mouseEnterDelay={0.8}>
                                <div style={{display: 'flex', flexDirection: 'column', width: 100}}>
                                    <span style={{fontSize: 14}}>{title}</span>
                                    <span style={{fontSize: 12}}><span style={{fontWeight: 'bold'}}>{t('Actions taken')}: </span>{_.get(raw, 'description')}</span>
                                    <span style={{fontSize: 12}}><span style={{fontWeight: 'bold'}}>{t('Recommended cycle')}: </span>{_.get(raw, 'recommendedServiceCycle')}</span>
                                </div>
                            </Tooltip>
                        }
                    }
                }
            }

            if(['SELECT','MULTISELECT'].indexOf(attribute.inputType) > -1 && attribute.entityType === 'file'){
                attribute = {
                    ...attribute,
                    inputProps: {
                        useOptionValueForValue: true,
                        renderOption: (title, value, raw) => {
                            return <Tooltip
                                title={<Image width={100} height={100} frame src={_.get(raw, 'thumbnailLink')}/>}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Image width={22} height={22} frame src={_.get(raw, 'thumbnailLink')}/>
                                    <div style={{marginLeft: 8, display: 'flex', flexDirection: 'column'}}>
                                        <span style={{fontSize: 14, fontWeight: 'bold'}}>{_.get(raw, 'basename')}</span>
                                    </div>
                                </div>
                            </Tooltip>
                        }
                    }
                }
            }

            if (attribute.addOn && _.isString(attribute.addOn.title) && attribute.addOn.title.includes("mailto")) {
                attribute.addOn = {
                    title: <a href={attribute.addOn.title}><Icon type={"mail"}/></a>
                }
            }

            return attribute;
        });

        const allFieldsDisabled = attributes.reduce((acc, cur) => {
            if (!acc) return acc;
            return cur.disabled || cur.inputType === 'LABEL'
        }, true)

        const formProps = {
            ...this.props,
            confirm,
            state: formStyles,
            afterSubmit,
            beforeSubmit,
            useTabs,
            className,
            editable,
            collapse,
            clearOnSubmit,
            layout,
            values: formValues,
            onChange: this.handleOnChange,
            attributes,
            groups,
            valueToolbarOnly: this.props.valueToolbarOnly,
            submitTitle: this.props.submitTitle,
            disableSubmit: disableSubmit || this.props.readOnly ? true : submit !== undefined ? !submit : editable,
            submit: this.handleSubmit,
            hideLabels,
            hideRequiredStar
        };

        return <>
            <div className={this.props.containerClassName} onDoubleClick={() => {
                //  console.log(JSON.stringify(_.find(attributes,{index: 'date'})));
                let logValues = formValues;
                Object.keys(logValues).forEach((key, index) => {
                    if (attributes.find(attr => attr.index === key && attr.inputType === "PASSWORD")) {
                        return logValues[key] = "***********"
                    }
                    return logValues[key] = logValues[key];
                });

                console.log('TDA Attributes:', attributes);
                console.log('TDA Values:', logValues);
            }} data-cy={this.props.formName}>

                {this.props.formType === 'modal' ?
                    <ModalAttributeForm
                        {...formProps}
                    />
                    : <AttributesForm
                        {...formProps}
                        beforeSubmit={beforeSubmit}
                    />}

                {this.state.errorText && this.props.errorPresentationMode === 'box' ?
                    <Alert message={this.state.errorText} type="error" showIcon/> : null}

            </div>
            {
                blockNavigationOnUnsavedChanges
                    ? <Prompt
                        when={when}
                        message={this.handleBlockedNavigation}/>
                    : null
            }
            {
                alertVisible && unsavedChanges
                    ? <Modal
                        visible={alertVisible}
                        onCancel={() => this.setState({alertVisible: false})}
                        title={t('Unsaved changes')}
                        footer={[
                            <Button key="1" onClick={() => this.setState({alertVisible: false})}>{t('Cancel')}</Button>,
                            <Button key="2" onClick={this.handleConfirmNavigationClick}>{t('Don`t save')}</Button>,
                            <Button key="3" type="primary" onClick={() => this.handleSubmit(changes)}>{t('Save')}</Button>
                        ]}
                    >
                        <span>{t('unsavedChanges')}</span>
                    </Modal>
                    : null
            }
        </>
    }
}

export default compose(
    withTranslation(),
    withRouter,
    withProfile(),
    withApollo,
    withProps((props) => {
        return {
            attributes: props.attributes || []
        }
    }),
    withProps((props) => {

        const resources = props.resources;
        const tmp = props.attributes?.filter((attribute) => {
            if (attribute.resources && attribute.resources?.length) {
                return checkResource(attribute.resources, resources)
            }
            return true;
        });


        return {
            submit: props.submit || props.useSubmit,
            groups: props.groups ? props.groups.map(item => (
                {
                    ...item,
                    index: item.index || item.categoryIndex,
                }
            )) : undefined,
            attributes: AttributeParser.parseTypeDefinitionAttributeList(tmp, {
                token: keycloak.token,
                t: props.t
            }),
            defaultValues: props.attributes.reduce((acc, cur) => {
                if(cur.value === ""){
                    acc[cur.index] = null;
                }else {
                    acc[cur.index] = cur.value
                }
                return acc;
            }, {})
        }
    })

)(TDAAttributesForm);
