// @flow
import React from "react"
import {useTranslation} from "react-i18next";
import Panel from "../Panel";
import _ from "lodash";
import DeviceConnectionQuery from "../../graphql/queries/deviceConnection.graphql";
import UpdateDeviceConnectionMutation from "../../graphql/mutations/updateDeviceConnection.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {useMutation, useQuery} from "@apollo/client";
import { Col, Row } from 'antd';
import CopyTextInput from "../CopyTextInput";

interface Props {
    id: string
}

const DeviceConnectionDashboard = ({id}: Props) => {
    const {t} = useTranslation();

    const deviceConnectionQuery = useQuery(DeviceConnectionQuery, {
        variables: {
            deviceConnectionId: id
        }
    })
    const [updateDeviceConnectionMutation] = useMutation(UpdateDeviceConnectionMutation, {
        refetchQueries: [
            {
                query: DeviceConnectionQuery,
                variables: {
                    id: id
                }
            }
        ]
    })

    let attributes = _.get(deviceConnectionQuery, 'data.deviceConnection.typeDefinitionAttributeList.typeDefinitionAttributes', [])
    let token = _.get(attributes.find((item) => item.index === 'token'), 'value') || null;
    attributes = attributes.filter((item) => !['token'].includes(item.index))

    return (
        <div>
            <Panel title={t('Edit')} style={{margin: 14}}>
                {
                    token ?
                        <Row align={"middle"} className={"mb-15"}>
                            <Col span={8}>
                                <Row align={"middle"} justify={"end"} style={{paddingRight: 8}}>
                                    {t("Token")}:
                                </Row>
                            </Col>
                            <Col span={16}>
                                <CopyTextInput text={token}/>
                            </Col>
                        </Row>
                        : null
                }

                <AttributesInlineForm
                    attributes={attributes}
                    layout={'horizontal'}
                    useSubmit={true}
                    t={t}
                    mutation={updateDeviceConnectionMutation}
                    translateMutationOptions={({values}) => ({
                        variables: {
                            deviceConnectionId: id,
                            input: values
                        }
                    })}
                />
            </Panel>
        </div>
    );
}

export default DeviceConnectionDashboard;
