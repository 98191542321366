// @flow
import React from 'react';
import {Input, message} from "antd";
import {useTranslation} from "react-i18next";
import {CopyOutlined} from "@ant-design/icons";

interface Props {
    text: string
}

const CopyTextInput = ({text}: Props) => {
    const {t} = useTranslation();

    const onCopy = () => {
        navigator.clipboard.writeText(text).then(function() {
                message.success(t('Copied to clipboard'));
        }, function(err) {
            message.error(`${t('Could not copy text')}: `, err);
        })
    }

    return (
        <div onClick={onCopy} style={{cursor: 'pointer'}}>
            <Input
                disabled={true}
                addonAfter={<CopyOutlined/>}
                value={text}
            />
        </div>
    );
}

export default CopyTextInput;
