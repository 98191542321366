// @flow
import React from "react"
import TypeDefinitionAttributesForm from "../TypeDefinitionAttributesForm";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import CreateDeviceConnectionMutation from "../../graphql/mutations/createDeviceConnection.graphql";

interface Props {
    onCreated: () => void
}

const DeviceConnectionCreateForm = ({onCreated}: Props) => {
    const {t} = useTranslation();
    const [createDeviceConnection] = useMutation(CreateDeviceConnectionMutation)

    return <TypeDefinitionAttributesForm
        index={'deviceConnection'}
        formProps={{
            useSubmit: true,
            submitTitle: t('Create'),
            mutation: createDeviceConnection,
            onSubmit: (res) => {
                if (onCreated) {
                    onCreated(res);
                }
            },
            translateMutationOptions: ({values}) => {
                console.log(values, 'VKASKD')
                return {
                    variables: {
                        input: {
                            ...values,
                            connectionType: "device"
                        }
                    }
                }
            }
        }}
    />
}

export default DeviceConnectionCreateForm;
